import logo from './logo.svg';
import './App.css';
import Menu from './Menu'; // Import a component from another file
import { Routes, Route, useLocation } from "react-router";
import Contact from './pages/Contact';
import CV from './pages/CV';
import Reviews from './pages/Reviews';
import Review from './pages/Review';
import Exhibitions from './pages/Exhibitions';
import Gallery from './pages/Gallery';
import Home from './pages/Home';

function App() {
  const location = useLocation();

  const isHome = (location.pathname == '/');

  const pageTitleClass =  isHome ? 'page-title-home' : 'page-title-non-home';

  return (
    <table width="100%" border="0" cellPadding="0" cellSpacing="0">
      <tbody>
        <tr>
          <td align="center">
            <div className="body-content">
              <div className={pageTitleClass}>Annabel Butler</div>
              <table width="100%" border="0" cellPadding="0" cellSpacing="0">
                <tbody>
                  <tr>
                    <td width="250" valign="top">
                      <Menu/>
                    </td>
                    <td valign="top">

                        <Routes>
                          <Route path="/" element={<Home />} />
                          <Route path="/solo" element={<SoloExhibitions />} />
                          <Route path="/group" element={<GroupExhibitions />} />
                          <Route path="/prizes" element={<Prizes />} />
                          <Route path="/commissions" element={<Commissions />} />

                          <Route path="/solo/:id" element={<SoloGallery />} />
                          <Route path="/group/:id" element={<GroupGallery />} />
                          <Route path="/prizes/:id" element={<PrizeGallery />} />
                          <Route path="/commissions/:id" element={<CommissionGallery />} />

                          <Route path="/solo/:id/:imageId" element={<SoloGallery />} />
                          <Route path="/group/:id/:imageId" element={<GroupGallery />} />
                          <Route path="/prizes/:id/:imageId" element={<PrizeGallery />} />
                          <Route path="/commissions/:id/:imageId" element={<CommissionGallery />} />

                          <Route path="/articles" element={<Reviews dataPath={`${process.env.PUBLIC_URL}/data/articles.json`}/>} />
                          <Route path="/articles/:id" element={<Review dataPath={`${process.env.PUBLIC_URL}/data/articles.json`} articlesPath={`${process.env.PUBLIC_URL}/collateral/articles`}/>} />
                          <Route path="/cv" element={<CV />} />
                          <Route path="/contact" element={<Contact />} />
                        </Routes>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="copyright">Copyright &copy; 2025 Annabel Butler</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function SoloExhibitions()   { return ( <Exhibitions title={"Solo Exhibitions"}  dataPath={`${process.env.PUBLIC_URL}/data/exhibitions-solo.json`}         imagePath={`${process.env.PUBLIC_URL}/images/exhibitions`} /> ); }
function GroupExhibitions()  { return ( <Exhibitions title={"Group Exhibitions"} dataPath={`${process.env.PUBLIC_URL}/data/exhibitions-group.json`}        imagePath={`${process.env.PUBLIC_URL}/images/exhibitions`} /> ); }
function Prizes()            { return ( <Exhibitions title={"Prizes"}            dataPath={`${process.env.PUBLIC_URL}/data/exhibitions-prizes.json`}       imagePath={`${process.env.PUBLIC_URL}/images/exhibitions`}/> ); }
function Commissions()       { return ( <Exhibitions title={"Commissions"}       dataPath={`${process.env.PUBLIC_URL}/data/exhibitions-commissions.json`}  imagePath={`${process.env.PUBLIC_URL}/images/exhibitions`}/>  ); }

function SoloGallery()       { return ( <Gallery title={"Solo Exhibitions"}  basePath='/solo'        dataPath={`${process.env.PUBLIC_URL}/data/gallery`} imagePath={`${process.env.PUBLIC_URL}/images/galleries`} collateralPath={`${process.env.PUBLIC_URL}/collateral`} /> ); }
function GroupGallery()      { return ( <Gallery title={"Group Exhibitions"} basePath='/group'       dataPath={`${process.env.PUBLIC_URL}/data/gallery`} imagePath={`${process.env.PUBLIC_URL}/images/galleries`} collateralPath={`${process.env.PUBLIC_URL}/collateral`} /> ); }
function PrizeGallery()      { return ( <Gallery title={"Prizes"}            basePath='/prizes'      dataPath={`${process.env.PUBLIC_URL}/data/gallery`} imagePath={`${process.env.PUBLIC_URL}/images/galleries`} collateralPath={`${process.env.PUBLIC_URL}/collateral`}/>  ); }
function CommissionGallery() { return ( <Gallery title={"Commissions"}       basePath='/commissions' dataPath={`${process.env.PUBLIC_URL}/data/gallery`} imagePath={`${process.env.PUBLIC_URL}/images/galleries`} collateralPath={`${process.env.PUBLIC_URL}/collateral`}/>  ); }



export default App;


