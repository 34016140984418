import './Home.css';
import { NavLink } from "react-router";

function Home() {
    const publicUrl = process.env.PUBLIC_URL;

    return (
        <div>
            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                <tbody>
                    <tr>
                        <td width="175" align="center">
                            <img width="150" border="0" src={`${publicUrl}/images/home/annabel-butler.jpg`}/><br/><br/>
                            Image credit: Andrew Treloar
                        </td>
                        <td valign="top">
                            <div className="news-text-no-border">Represented by Stella Downer Fine Art, Sydney</div>
                            <br/><br/>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br/>
        
            <div className="news-text">News &amp; Exhibitions</div>
            <br/>
            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                <tbody>
                    <tr>
                        <td width="175" align="center">
                            <NavLink to="/group/092"><img width="150" border="0" src={`${publicUrl}/images/exhibitions/92.jpg`}/></NavLink>
                        </td>
                        <td valign="top">
                            <b><NavLink to="/group/092">Summer 2025</NavLink></b>
                            <br/>
                            4 February - 1 March 2025
                            <br/><br/>
                            Stella Downer Fine Art
                            <br/>
                        </td>
                    </tr>
                </tbody>
            </table>   

            <br/>   
            <br/>

            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                <tbody>
                    <tr>
                        <td width="175" align="center">
                            <img width="150" border="0" src={`${publicUrl}/images/home/solo-2025.jpg`}/>
                        </td>
                        <td valign="top">
                            <b>2025 Solo Exhibition</b>
                            <br/>
                            6 August - September 2025
                            <br/><br/>
                            Stella Downer Fine Art
                            <br/>
                        </td>
                    </tr>
                </tbody>
            </table>   

            <br/>   
            <br/>


            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                <tbody>
                    <tr>
                        <td width="175" align="center">
                            <img width="150" border="0" src={`${publicUrl}/images/home/primrose-park.jpg`}/>
                        </td>
                        <td valign="top">
                            <b>2025 Primrose Park Artist in Residence</b> <br/><br/>
                            <a target="_blank" href="https://www.northsydney.nsw.gov.au/arts-2/primrose-park-artists-studios"> Find out more</a>
                            <br/>
                            <br/>
                            Next Open Studio Day  -  Sunday 23 March, 10-3pm. All Welcome.
                        </td>
                    </tr>
                </tbody>
            </table>  

            <br/>
            <br/>   

            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                <tbody>
                    <tr>
                        <td width="175" align="center">
                            <img width="150" border="0" src={`${publicUrl}/images/home/air-house.jpg`}/>
                        </td>
                        <td valign="top">
                            <b>2024 Georges River Artist in Residence</b> <br/><br/>
                            <a target="_blank" href="https://www.georgesriver.nsw.gov.au/Community/Art-and-Culture/Hurstville-Museum-Gallery/Artist-in-Residence"> Find out more</a>
                        </td>
                    </tr>
                </tbody>
            </table>  

            <br/>
            <br/>   

            <div className="news-text">Teaching 2025</div>
            <br/>
            The National Art School
            <br/>
            Painting Lecturer,
            <br/>
            National Art School Short Courses <a href="https://nas.edu.au/" target="_blank">https://nas.edu.au/</a> 
            <br/><br/>
        </div>
    )
}

export default Home;