import { useParams } from 'react-router';
import './Review.css';
import { useState, useEffect } from 'react';

function Review({dataPath, articlesPath}) {
    let params = useParams();

    const [items, setData] = useState(null);
  
    useEffect (() => {
      fetch(dataPath)
        .then(response => response.json())
        .then(json => setData(json))
        .catch(error => console.error(error));
    }, []);
  
    var rendered = null;
    if (items) {
        const item = items.find(i => i.id == params.id);

        const path = `${articlesPath}/${item.image}`

        rendered = (
            <div>
                <div className="page-title">Reviews &amp; Press</div>
                <br/>
                <p>
                    {item.author}
                    {item.author && ", "}
                    <i>&quot;{item.title}&quot;</i>
                    {item.title && ", "}
                    {item.publication}
                    {item.page && " ("}
                    {item.page}
                    {item.page && ")"}
                    {item.publication && ", "}
                    {item.date}
                    | <a href={path} target="_new">View in New Browser Window</a>.
                </p>
                <br/>
                <div className="press-clipping">
                <img src={path} />
                </div>
            </div>
        );
    }

    return (
        rendered
    );
}


export default Review;