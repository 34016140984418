import './CV.css';

function CV() { 
    return ( 
        <div>
            <span className="page-title">CV</span>
            <br/><br/>
            <div><b>Qualifications</b></div>
            <table width="600" cellPadding="4" cellSpacing="0" border="0">
                <tbody>
                    <tr><td width="200">2002</td><td>BFA Hons (Painting), National Art School, Sydney</td></tr>
                    <tr><td width="200">2000</td><td>New York Studio School of Drawing, Painting and Sculpture </td></tr>
                    <tr><td width="200">1990</td><td>BA/LLB, Australian National University </td></tr>
                </tbody>
            </table>
            <br/><br/>
            <div><b>Residencies</b></div>
            <table width="600" cellPadding="4" cellSpacing="0" border="0">
                <tbody>
                    <tr><td width="200">2025</td><td>Primrose Park Artist in Residence, NSW</td></tr>
                    <tr><td width="200">2024</td><td>Georges River Artist in Residence, NSW</td></tr>
                    <tr><td width="200">2006</td><td>AGNSW Residency, Moya Dyring Studio, Cite Internationale des Arts, Paris </td></tr>
                    <tr><td width="200">2003</td><td>Artist in Residence, Bundanon, NSW </td></tr>
                    <tr><td width="200">2003</td><td>Chautauqua Institution Residency, USA </td></tr>
                    <tr><td width="200">2001</td><td>Vermont Studio Center Residency, USA </td></tr>
                </tbody>
            </table>
            <br/><br/>
            <div><b>Scholarships, Prizes</b></div>
            <table width="600" cellPadding="4" cellSpacing="0" border="0">
                <tbody>
                    <tr><td width="200">2019</td><td>Winner - 9x5 Landscape Prize, Sydney</td></tr>
                    <tr><td width="200">2016</td><td>Highly Commended - 9x5 Landscape Prize, Sydney</td></tr>
                    <tr><td width="200">2013</td><td>Winner - Open Art Prize, Glebe Art Show, Sydney </td></tr>
                    <tr><td width="200">2006</td><td>Highly Commended - Jacques Cadry Memorial Art Prize</td></tr>
                    <tr><td width="200">2004</td><td>Winner - Open Art Prize, Glebe Art Show, Sydney</td></tr>
                    <tr><td width="200">2004</td><td>Winner - Oil Prize, Waverley Art Show, Sydney </td></tr>
                    <tr><td width="200">2003</td><td>Chautauqua Institution Scholarship, USA </td></tr>
                    <tr><td width="200">2003</td><td>Dame Joan Sutherland Fund, American Australian Association, USA </td></tr>
                    <tr><td width="200">1998</td><td>Julian Beaumont Travelling Scholarship to Byam Shaw School of Art, London </td></tr>
                </tbody>
            </table>
            <br/><br/>
            <div><b>Solo Exhibitions</b></div>
            <table width="600" cellPadding="4" cellSpacing="0" border="0">
                <tbody>
                    <tr><td width="200">2022</td><td><i>Sydney Scapes</i>, Stella Downer Fine Art, Sydney</td></tr>   
                    <tr><td width="200">2018</td><td><i>Barnacles</i>, Stella Downer Fine Art, Sydney</td></tr>    
                    <tr><td width="200">2015</td><td><i>Reconstructed</i>, Stella Downer Fine Art at Depot II Gallery, Sydney,<br/> Two-person exhibition with Mark Bailey</td></tr>
                    <tr><td width="200">2012</td><td><i>Over the Rooftops</i>, ANCA Gallery, ACT </td></tr>
                    <tr><td width="200">2011</td><td><i>Dense Living</i>, Brunswick Street Gallery, Melbourne </td></tr>
                    <tr><td width="200">2009</td><td><i>The Built Environment</i>, Charles Hewitt, Sydney </td></tr>
                    <tr><td width="200">2007</td><td><i>Postcards from Paris</i>, Charles Hewitt, Sydney </td></tr>
                    <tr><td width="200">2005</td><td><i>Recent Works</i>, Charles Hewitt, Sydney </td></tr>
                    <tr><td width="200">2003</td><td><i>Cityscapes - New York &amp; Sydney</i>, Australian Consulate, New York, USA </td></tr>
                    <tr><td width="200">2001</td><td><i>Scapes - snow, sea &amp; land</i>, Artspace 71, ACT </td></tr>
                </tbody>
            </table>
            <br/><br/>
            <div><b>Selected Group Exhibitions</b></div>
            <table width="600" cellPadding="4" cellSpacing="0" border="0">
                <tbody>
                    <tr><td width="200">2025</td><td><i>Summer 2025</i>, Stella Downer Fine Art, Sydney</td></tr>
                    <tr><td width="200">2024</td><td><i>New York Studio School Alumni Exhibition</i>, New York</td></tr>
                    <tr><td width="200">2024</td><td><i>Summer 2024</i>, Stella Downer Fine Art, Sydney</td></tr>
                    <tr><td width="200">2023</td><td><i>Fisher's Ghost Art Award 2023</i>, Campbelltown Art Centre</td></tr>  
                    <tr><td width="200">2023</td><td><i>Summer 2023</i>, Stella Downer Fine Art, Sydney</td></tr>
                    <tr><td width="200">2022</td><td><i>Fisher's Ghost Art Award 2022</i>, Campbelltown Art Centre</td></tr> 
                    <tr><td width="200">2022</td><td><i>Calleen Art Award 2022</i>, Cowra Regional Art Gallery</td></tr> 
                    <tr><td width="200">2022</td><td><i>Little Things Art Prize 2022</i>, Saint Cloche Gallery, Sydney</td></tr> 
                    <tr><td width="200">2022</td><td><i>Virtual New York Studio School Alumni Exhibition</i>, New York</td></tr> 
                    <tr><td width="200">2021</td><td><i>Coast to Coast - Ukraine Crisis fundraising exhibition &amp; auction</i>, Saint Cloche Gallery, Sydney</td></tr>
                    <tr><td width="200">2021</td><td><i>GreenWay Art Prize 2021</i>, Flour Mills Complex, Summer Hill</td></tr>   
                    <tr><td width="200">2021</td><td><i>Fisher's Ghost Art Award 2021</i>, Campbelltown Art Centre</td></tr>
                    <tr><td width="200">2021</td><td><i>Little Things Art Prize 2021</i>, Saint Cloche Gallery, Sydney</td></tr>
                    <tr><td width="200">2021</td><td><i>Korea-Australia Arts Foundation Art Prize 2021</i>, Korean Cultural Centre, Sydney</td></tr>
                    <tr><td width="200">2021</td><td><i>Virtual New York Studio School Alumni Exhibition</i>, New York</td></tr> 
                    <tr><td width="200">2021</td><td><i>Summer 2021</i>, Stella Downer Fine Art, Sydney</td></tr>
                    <tr><td width="200">2020</td><td><i>Kangaroo Valley Art Prize 2020</i>, Kangaroo Valley</td></tr>
                    <tr><td width="200">2020</td><td><i>Little Things Art Prize 2020</i>, Saint Cloche Gallery, Sydney</td></tr>   
                    <tr><td width="200">2020</td><td><i>Summer - Renewal</i>, Stella Downer Fine Art, Sydney</td></tr>
                    <tr><td width="200">2019</td><td><i>Winner, 9x5 Landscape Prize 2019</i>, Sydney</td></tr>
                    <tr><td width="200">2019</td><td><i>Calleen Art Award 2019</i>, Cowra Regional Art Gallery</td></tr>
                    <tr><td width="200">2019</td><td><i>Summer 2019</i>, Stella Downer Fine Art, Sydney</td></tr>
                    <tr><td width="200">2018</td><td><i>9x5 Landscape Prize 2018</i>, Sydney</td></tr>
                    <tr><td width="200">2018</td><td><i>Paddington Art Prize 2018</i>, Sydney</td></tr>
                    <tr><td width="200">2018</td><td><i>Calleen Art Award 2018</i>, Cowra Regional Art Gallery</td></tr>
                    <tr><td width="200">2018</td><td><i>Art and its Making</i>, Bondi Pavillion, Sydney</td></tr>
                    <tr><td width="200">2018</td><td><i>Froghollow Studios Annual Exhibition</i>, Aro Gallery, Sydney</td></tr>
                    <tr><td width="200">2017</td><td><i>Re-Creation</i>, Stella Downer Fine Art, Sydney</td></tr>
                    <tr><td width="200">2017</td><td><i>A Stella(r) Renaissance</i>, Stella Downer Fine Art, Sydney</td></tr>
                    <tr><td width="200">2016</td><td><i>9x5 Landscape Prize 2016</i>, Sydney</td></tr>
                    <tr><td width="200">2016</td><td><i>Eutick Memorial Still Life Award 2016</i>, Coffs Harbour Regional Gallery</td></tr>
                    <tr><td width="200">2016</td><td><i>Kaleidoscope</i>, Bondi Pavillion, Sydney</td></tr>
                    <tr><td width="200">2016</td><td><i>William Fletcher Awards</i>, Artarmon Galleries, Sydney</td></tr>
                    <tr><td width="200">2016</td><td><i>New York Studio School Invitational</i>, NYSS, New York</td></tr>
                    <tr><td width="200">2016</td><td><i>Kogarah Art Prize 2016</i>, Sydney</td></tr>
                    <tr><td width="200">2016</td><td><i>Stanthorpe Arts Festival 2016</i>, Stanthorpe Regional Art Gallery, Queensland</td></tr>
                    <tr><td width="200">2015</td><td><i>Deck the Walls</i>, Stella Downer Fine Art, Sydney</td></tr>
                    <tr><td width="200">2015</td><td><i>Korea-Australia Arts Foundation Art Prize 2015</i>, Korean Cultural Centre</td></tr>
                    <tr><td width="200">2015</td><td><i>Fisher's Ghost Art Award 2015</i>, Campbelltown Art Centre</td></tr>
                    <tr><td width="200">2015</td><td><i>Mosman Art Prize 2015</i>, Sydney</td></tr>
                    <tr><td width="200">2015</td><td><i>NSW Parliament Plein Air Painting Prize 2015</i>, Sydney</td></tr>
                    <tr><td width="200">2014</td><td><i>NSW Parliament Plein Air Painting Prize 2014</i>, Sydney</td></tr>
                    <tr><td width="200">2014</td><td><i>Kogarah Art Prize 2014</i>, Sydney</td></tr>
                    <tr><td width="200">2013</td><td><i>Mosman Art Prize 2013</i>, Sydney</td></tr>    
                    <tr><td width="200">2013</td><td><i>NSW Parliament Plein Air Painting Prize 2013</i>, Sydney </td></tr>    
                    <tr><td width="200">2013</td><td><i>Adelaide Perry Prize for Drawing 2013</i>, Sydney</td></tr>
                    <tr><td width="200">2012</td><td><i>Views &amp; Visions</i>, Charles Hewitt Gallery, Sydney</td></tr>
                    <tr><td width="200">2011</td><td><i>Gesture - The Art of Fresh Forms</i>, Brunswick Street Gallery, Melbourne</td></tr> 
                    <tr><td width="200">2010</td><td><i>About Flowers</i>, Charles Hewitt, Sydney</td></tr> 
                    <tr><td width="200">2009</td><td><i>Portia Geach Memorial Award 2009</i>, S.H Erwin Gallery, Sydney</td></tr> 
                    <tr><td width="200">2009</td><td><i>Art Melbourne 09</i>, Royal Exhibition Building, Melbourne</td></tr>
                    <tr><td width="200">2008</td><td><i>Fisher's Ghost Art Award</i>, Campbelltown Arts Centre </td></tr>
                    <tr><td width="200">2008</td><td><i>Art Brisbane 08</i>, Brisbane Convention &amp; Exhibiton Centre </td></tr>
                    <tr><td width="200">2008</td><td><i>Australia, Myth &amp; Reality</i>, Maison des Sciences de L'Homme, Paris, France </td></tr>
                    <tr><td width="200">2007</td><td><i>Imagined Australia</i>, Palazzo Vaj, Prato, Italy </td></tr>
                    <tr><td width="200">2006</td><td><i>Jacques Cadry Memorial Art Prize</i>, Sydney - Highly Commended </td></tr>
                    <tr><td width="200">2005</td><td><i>Art Sydney 05</i>, Horden Pavilion &amp; Royal Hall of Industries, Sydney </td></tr>
                    <tr><td width="200">2005</td><td><i>Paddington Art Prize 2005</i>, Marlene Antico Fine Art, Sydney </td></tr>
                    <tr><td width="200">2005</td><td><i>Hazelhurst Art Award 2005</i>, Hazelhurst Regional Gallery, Sydney </td></tr>
                    <tr><td width="200">2004</td><td><i>Art on the Rocks</i>, ASN Gallery, Sydney </td></tr>
                    <tr><td width="200">2003</td><td><i>Chautauqua 2003</i>, Logan Gallery, Chautauqua, New York, USA </td></tr>
                    <tr><td width="200">2002</td><td><i>New York Studio School Alumni</i>, Frederieke Taylor Gallery, New York, USA </td></tr>
                    <tr><td width="200">1999</td><td><i> Implant (3-D wall pieces)</i>, Level Gallery, Sydney </td></tr>
                </tbody>
            </table>
            <br/><br/>
            <div><b>Bibliography</b></div>
            <table width="600" cellPadding="4" cellSpacing="0" border="0">
                <tbody>
                    <tr><td>&quot;If I Could Have&quot;, <i>Art Collector</i>, Issue 82, Oct-Dec 2017, p84</td></tr>
                    <tr><td>Elizabeth Fortescue, &quot;A brush with artists&quot;, <i>The Daily Telegraph</i>, 5 March 2016, Best Weekend, p4</td></tr>
                    <tr><td>Ron Cerabona, &quot;View from Above&quot;, <i>The Canberra Times</i>, 15 September 2012, Panorama p16</td></tr>
                    <tr><td>Elizabeth Fortescue, &quot;As Seine from above&quot;, <i>The Daily Telegraph</i>, 7 August 2007, p32 </td></tr>
                    <tr><td>Anna Fenech, &quot;Global Glimpse&quot;, <i>The Australian</i>, 7 September 2005, Wealth p2 </td></tr>
                    <tr><td>Pam Walker, &quot;Big red tractor is right off the wall&quot;, <i>City News</i>, September 2005, p18 </td></tr>
                    <tr><td>Tracey Clement, &quot;Critic's Picks&quot;, <i>The Sydney Morning Herald</i>, 24 March 2005, Metro, p27 </td></tr>
                    <tr><td>Interview with Gerard Callinan, NSW Country Hour, ABC Radio, 7 March 2005 </td></tr>
                    <tr><td>Roger Coda, &quot;Australian and Tunisian exhibit&quot;, <i>The Chautauquan Daily</i>, 28 July 2003, p6 </td></tr>
                    <tr><td>Sebastian Smee, &quot;Space Shuttle&quot;, <i>The Sydney Morning Herald</i>, 6 May 1999 </td></tr>
                </tbody>
            </table>
            <br/><br/>
            <div><b>Collections</b></div>
            <table width="600" cellPadding="4" cellSpacing="0" border="0">
                <tbody>
                    <tr><td>National Art School Collection</td></tr>
                    <tr><td>Hurstville Museum &amp; Gallery</td></tr>
                    <tr><td>Chau Chak Wing Museum, University of Sydney</td></tr>
                    <tr><td>Bundanon Trust</td></tr>
                    <tr><td>Art Spectrum</td></tr>
                    <tr><td>Gilbert &amp; Tobin Lawyers</td></tr>
                    <tr><td>Private collections in Australia, New Zealand, Singapore, USA, UK, Europe</td></tr>    
                </tbody>
            </table>  
        </div>
    ); 
  }
  
  export default CV;

  