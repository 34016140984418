import { NavLink, useParams } from 'react-router';
import './Exhibitions.css';
import { useState, useEffect } from 'react';

function Exhibitions({title, dataPath, imagePath}) {
    const [items, setData] = useState(null);
  
    useEffect (() => {
      fetch(dataPath)
        .then(response => response.json())
        .then(json => setData(json))
        .catch(error => console.error(error));
    }, []);
  
    const rows = [];
    if (items) {
        for (let i = 0; i < items.exhibitions.exhibition.length; i++) {
            rows.push(<Exhibition item={items.exhibitions.exhibition[i]} imagePath={imagePath} key={i} />);
        }
    }
      
    return (
        <div>
            <span className="page-title">{title}</span>
            <br/><br/>
            {rows}
        </div>
    );
}

function _formatDate(date, maskDate)
{
    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    if (date.length == 0) // no date
    {
        return "";
    } 
    if (date.length == 4) // yyyy
    {
        return date
    }
    if (date.length == 6) // yyyymm
    {
        const yyyy = date.substring(0, 4);
        const mm = parseInt(date.substring(4, 6));
        return `${months[mm]} ${yyyy}`
    }
    else if (date.length == 8) // yyyymmdd
    {
        const yyyy = date.substring(0, 4);
        const mm = parseInt(date.substring(4, 6)) - 1; // zero based array lookup
        const dd = parseInt(date.substring(6, 8));

        if (date.substring(0, 6) == maskDate.substring(0,6)) // yyyymm match
        {
            return dd;
        }

        if (date.substring(0, 4) == maskDate.substring(0,4)) // yyyy match
        {
            return `${dd} ${months[mm]}`;
        }

        return `${dd} ${months[mm]} ${yyyy}`;
    }

}

function formatDate(startDate, endDate)
{
    var fmtStartDate = _formatDate(startDate, endDate);
    var fmtEndDate = _formatDate(endDate, "");

    if (fmtStartDate.length == 0 && fmtEndDate.length == 0)
    {
        return "";
    }
    
    if (fmtEndDate.length == 0)
    {
        return fmtStartDate;
    }

    if (fmtStartDate.length == 0)
    {
        return fmtEndDate;
    }

    if (fmtStartDate == fmtEndDate)
    {
        return fmtStartDate;
    }

    return `${fmtStartDate} - ${fmtEndDate}`;
}

function Exhibition({item, imagePath}) {
    const location = [].concat(item.location)
        .map((row, index) => <div key={index}>{row}</div>)

    return ( 
        <div className="exhibition-container">
          <table width="100%" border="0" cellSpacing="0" cellPadding="0">
            <tbody>
                <tr>
                    <td width="200" align="center">
                        <NavLink to={item.id}>
                            <img width="150" border="0" src={`${imagePath}/${item.thumbnail}`}/>
                        </NavLink>
                    </td>
                    <td valign="top">
                        <b><NavLink to={item.id}>{item.title}</NavLink></b>
                        {item.subTitle && <br/>}
                        {item.subTitle}
                        <br/>
                        {formatDate(item.startDate, item.endDate)}
                        <hr/>
                        {item.highlight && <b>{item.highlight}</b>}
                        {item.exhibiter && <div>{item.exhibiter}</div>}
                        {item.location && <div>{location}</div>}
                        {item.website && <div><a target="_new"  href="{item.website}">website</a></div>}
                    </td>
                </tr>
            </tbody>
          </table>
        </div>
    );
}

export default Exhibitions;