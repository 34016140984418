import './Reviews.css';
import { useState, useEffect } from 'react';

function Reviews({dataPath}) { 
    const [items, setData] = useState(null);
  
    useEffect(() => {
      fetch(dataPath)
        .then(response => response.json())
        .then(json => setData(json))
        .catch(error => console.error(error));
    }, []);
  
    const rows = [];
    if (items) {
        for (let i = 0; i < items.length; i++) {
            rows.push(<ReviewItem item={items[i]} key={i} />);
        }
    }
  
    return (
        <div>
            <div className="page-title">Reviews &amp; Press</div>
            <ol>
                {rows}
            </ol>
        </div>
    );
  }


function ReviewItem(item) {
    var title;
    if (item.item.image) {
        title = <a href={"/articles/" + item.item.id}><i>&quot;{item.item.title}&quot;</i></a>
    } else {
        title = <span><i>&quot;{item.item.title}&quot;</i></span>
    }

    return (
        <li>
            {item.item.author}
            {item.item.author && ", "}
            {title}
            {item.item.title && ", "}
            {item.item.publication}
            {item.item.page && " ("}
            {item.item.page}
            {item.item.page && ")"}
            {item.item.publication && ", "}
            {item.item.date}
        </li>
    );
}

export default Reviews;

