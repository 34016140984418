import { NavLink, useParams, useSearchParams } from 'react-router';
import './Gallery.css';
import { useState, useEffect } from 'react';

function Gallery({basePath, title, dataPath, imagePath, collateralPath}) {
    let params = useParams();
    let [searchParams] = useSearchParams();

    const [items, setData] = useState(null);
  
    useEffect (() => {
      fetch(`${dataPath}/${params.id}.json`)
        .then(response => response.json())
        .then(json => setData(json))
        .catch(error => console.error(error));
    }, [dataPath, params.id]);

    var currentImageIndex = -1;
    var exhibitionName = null;
    var navItems = [];
    var types = [];
    var images = [];
    var maybeTypeParam = null;

    if (items) {
        exhibitionName = items.gallery.title;

        // determine the distinct types found among all the images
        types = Array.from(new Set(items.gallery.galleryImage.map(g => g.type)))
            .map(t => {
                const sanitisedType = t.replace(' ', '-').toLowerCase();
                return {
                    title: t,
                    sanitised: sanitisedType,
                    path: `${basePath}/${params.id}?t=${sanitisedType}` 
                }

            });

        // if the type get parameter doesn't match a valid type, clear it
        maybeTypeParam = searchParams.get("t")
        if (types.findIndex(t => t.sanitised === maybeTypeParam) === -1) {
            maybeTypeParam = null;
        }

        // if there is a valid type parameter use it to filter the images, otherwise return them all
        images = items.gallery.galleryImage
            .filter(g => maybeTypeParam ?  g.type.replace(' ', '-').toLowerCase() === maybeTypeParam : true);

        // build the nave nav items off the maybe filtered set of images and propagate the type parameter if it is present
        navItems = images.map((g, index) => {
            const maybeType = maybeTypeParam ? `?t=${maybeTypeParam}` : ''
            return { 
                title: index+1,
                path: `${basePath}/${params.id}/${g.id}${maybeType}`
            }
        });

        if (params.imageId) {
            currentImageIndex = images.findIndex (g => g.id === params.imageId)
            if (currentImageIndex === -1 )  { 
                // not found - select the first
                currentImageIndex = 0;
            }
        } else {
            currentImageIndex = 0;
        }
    }

    return (
        <div>
            <span className="parent-title">{title}</span> 
            {exhibitionName && <span className="page-title">&nbsp;{exhibitionName}</span>}
            <br/><br/><br/><br/><br/>
            { currentImageIndex > -1 && <Image image={images[currentImageIndex]} imagePath={`${imagePath}/${params.id}`}/> }
            <br/><br/><br/><br/>
            { types && types.length > 1 && <Types types={types} maybeSelectedType={maybeTypeParam}/>}
            {items && <Collateral gallery={items.gallery} collateralPath={collateralPath}/>}
            <br/><br/>
            { navItems && <Nav items={navItems} selectedItemIndex={currentImageIndex} /> }
        </div>
    );
}

function Image({image, imagePath}) 
{
    const status = (image.status && image.status.length > 0) ? image.status : 'Sold';

    var mediaAndDimensions = [];
    if (image.media      && image.media.length > 0)      mediaAndDimensions.push(image.media);
    if (image.dimensions && image.dimensions.length > 0) mediaAndDimensions.push(image.dimensions);

    return (
        <table width="725" border="0" cellSpacing="0" cellPadding="0">
            <tbody>
                <tr>
                    <td width="440" className="galleryImage" align="center" valign="center">
                        <img alt={image.title} src={`${imagePath}/${image.fileName}`} />	     
                    </td>
                    <td width="25">&nbsp;</td>
                    <td width="260">
                        <div className="galleryImageInformation">
                            { image.prefix && <div><b>{image.prefix}</b></div> }
                            <div><b>{ image.title }</b></div>
                            { mediaAndDimensions.length > 0 && <div>{mediaAndDimensions.join(', ')}</div> }
                            <br/>
                            <div> {status} </div>                            
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

function Nav({items, selectedItemIndex})
{
    var links = items.map((item, index)  => {
        var link = <span key={item.path}> <NavLink to={item.path}>{item.title}</NavLink> | </span>
        if (index === selectedItemIndex) {
            link = <span key={item.path}> {item.title} | </span>
        }
        return link;
    }); 

    var prevLink = <span key="prev"> Previous | </span>
    if (selectedItemIndex > 0) {
        const item = items[selectedItemIndex - 1]
        prevLink = <span key="prev"> <NavLink to={item.path}>Previous</NavLink> | </span>
    }
    links.push(prevLink);

    var nextLink = <span key="next"> Next </span>
    if (selectedItemIndex < items.length - 1) {
        const item = items[selectedItemIndex + 1]
        nextLink = <span key="next"> <NavLink to={item.path}>Next</NavLink> </span>
    }
    links.push(nextLink);

    return links;
}

function Types({types, maybeSelectedType}) {
    var links = types.map((type)  => {
        var link = <span key={`${type.sanitised}`}> | <NavLink to={type.path}>{type.title}</NavLink> </span>
        if (type.sanitised === maybeSelectedType) {
            link = <span key={`${type.sanitised}`}> | {type.title} </span>
        }
        return link;
    }); 

    if (maybeSelectedType) {
        links.unshift(<span key="all"> <NavLink to={"."}>All</NavLink> </span>)
    } else {
        links.unshift(<span key="all"> All </span>)
    }
    return <span> {links} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>;
}

function Collateral({gallery, collateralPath}) {
    var items = [];

    if (gallery.pressReleaseId && gallery.pressReleaseId.length > 0) {
        items.push({
            key: 'media-release',
            title: 'Media Release',
            path: `${collateralPath}/press-releases/${gallery.pressReleaseId}.pdf`,
            useRouter: false
        });
    }

    if (gallery.reviewId && gallery.reviewId.length > 0) {
        items.push({
            key: 'review',
            title: 'Review',
            path: `/articles/${gallery.reviewId}`,
            useRouter: true
        });
    }

    if (gallery.artistStatementId && gallery.artistStatementId.length > 0) {
        items.push({
            key: 'artist-statement',
            title: 'Artist Statement',
            path: `${collateralPath}/statements/${gallery.artistStatementId}.pdf`,
            useRouter: false
        });
    }

    if (gallery.otherStatementId && gallery.otherStatementId.length > 0) {
        items.push({
            key: 'other-statement',
            title: gallery.otherStatementText,
            path: `${collateralPath}/statements/${gallery.otherStatementId}.pdf`,
            useRouter: false
        });
    }

    if (gallery.otherStatementId2 && gallery.otherStatementId2.length > 0) {
        items.push({
            key: 'other-statement-2',
            title: gallery.otherStatementText2,
            path: `${collateralPath}/statements/${gallery.otherStatementId2}.pdf`,
            useRouter: false
        });
    }

    var links = items.map((item, index)  => {
        var link = <span key={item.key}> {index > 0 && <span>|</span>} <a href={item.path} target="_blank">{item.title}</a> </span>
        if (item.useRouter === true) {
            link = <span key={item.key}> {index > 0 && <span>|</span>} <NavLink to={item.path}>{item.title}</NavLink> </span>
        }
        return link;
    }); 
  
    return links;
}

export default Gallery;