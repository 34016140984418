import './Contact.css';

function Contact() { 
    return ( 
      <div>
        <br/><br/>
        <p>
        To contact Annabel, please email her at the following address:
        </p>
        <div className="contact-information">
          &nbsp; &nbsp; <a href="mailto:annabel_butler@yahoo.com" className="contact-email">annabel_butler@yahoo.com</a>
        </div>  
      </div>
    ); 
  }
  
  export default Contact;
  