import logo from './logo.svg';
import './Menu.css';
import { NavLink } from "react-router";

function Menu() {
  var items = [
      { route: "/", title: "home" }, 
      { route: "/solo", title: "solo exhibitions" }, 
      { route: "/group", title: "group exhibitions" }, 
      { route: "/prizes", title: "prizes" }, 
      { route: "/commissions", title: "commissions" }, 
      { route: "/articles", title: "reviews & press" }, 
      { route: "/cv", title: "cv" }, 
      { route: "/contact", title: "contact" }
  ];

  const rows = [];
  for (let i = 0; i < items.length; i++) {
      rows.push(<MenuItem item={items[i]} key={i} />);
  }

  return (
    <nav>
     {rows}
    </nav>
  );
}

function MenuItem({item}) {
  return ( 
    <NavLink className={({ isActive }) => isActive ? "menu-item-selected" : "menu-item"} to={item.route}>
      {item.title}
    </NavLink>
  );
}


export default Menu;
